<template>
    <div>
        <div class="columns is-mobile is-multiline">
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Name') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="period.name" disabled>
                    </div>
                </div>
            </div>

            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Start date') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="period.start_date" disabled>
                    </div>
                </div>
            </div>

            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('End date') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="period.end_date" disabled>
                    </div>
                </div>
            </div>
        </div>
        </div>
</template>

<script>
export default {
    name: 'Show',
    inject: ['route', 'http', 'i18n'],
    data() {
        return {
            period: {},
        };
    },

    mounted() {
        this.fetchData();
    },

    methods: {
        fetchData() {
            this.http
                .get(this.route('period.show', { period: this.$route.params.period }))
                .then(({ data }) => {
                    this.period = data.period;
                    this.period.periodicity = this.i18n(this.period.periodicity);
                })
                .catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss" scoped>
.medal {
    width: 10rem;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

@media (max-width: 748px) {
    .medal {
        width: 5.5rem;
    }
}
</style>
